import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactHtmlParser from "react-html-parser";
import SEO from "../components/seo";
import Layout from "../components/layout";

// import moonSvg from "../images/noun_moon_302494.svg";
// import landerSvg from "../images/noun_lunarlander_302042.svg";
// import astronautSvg from "../images/noun_astronaut_302045.svg";

// import astronautFlagImg  from "../images/astro_flag_profile.jpg";
import rover1 from "../images/rover.png";
// import rover2 from "../images/rover2.png";
// import rover3 from "../images/rover3.png";

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
    const { location } = props;
    console.log(location)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
        query {
          wpgraphql {
            page(id: "cGFnZTo4") {
              content
              title
              slug
            }
          }
        }
        `}
        render={data => 
          <Layout location={this.props.location}>
            <SEO
              keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
              title="Home"
            />
            <section id="what-is-virtual-moon" className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
              <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
              <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content) }</div>;
              <div></div>
            </section>
          </Layout>
        }
      />)
  }
}

Index.propTypes = {
  location: PropTypes.object
};


export default Index;

